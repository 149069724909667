import Vue from 'vue'
import Vuex from 'vuex'

import {auth} from './modules/auth'
import {id} from './modules/id'
import {machine} from './modules/machine'
import {web} from './modules/web'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
      auth,
      id,
      machine,
      web,
    }
  })