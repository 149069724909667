import Vue from "vue";
import VueRouter from "vue-router";
import SplashScreen from "../views/SplashScreen.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SplashScreen",
    component: SplashScreen,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/pincode",
    name: "Pincode",
    component: () => import("@/views/Pincode.vue"),
  },
  {
    path: "/create_pincode",
    name: "CreatePincode",
    component: () => import("@/views/CreatePincode.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    path: "/manage",
    name: "Manage",
    component: () => import("@/views/Manage.vue"),
  },
  {
    path: "/account_setting",
    name: "account_setting",
    component: () => import("@/views/Setting/Account.vue"),
  },
  {
    path: "/round_setting",
    name: "round_setting",
    component: () => import("@/views/Setting/Round.vue"),
  },
  {
    path: "/machine_setting",
    name: "machine_setting",
    component: () => import("@/views/Setting/Machine.vue"),
  },
  {
    path: "/time_setting",
    name: "time_setting",
    component: () => import("@/views/Setting/SetTime.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
