import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

Vue.config.productionTip = false
Vue.prototype.$http = axios;

Vue.prototype.$headers =  {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Authorization": "Bearer " + localStorage.getItem("blog_token"),
    },
};

// axios.defaults.baseURL = 'http://192.168.1.2/bot/service/';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// axios.defaults.baseURL = 'https://bot.keep116.com/service/';
axios.defaults.baseURL = '/service/';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
