<template>
  <div :class="{ loader: true, fadeout: !isLoading }">Auto Bot Yod Ronaldo - Version 0.1 ...</div>
</template>

<script>
export default {
  name: "SplashScreen",
  props: ["isLoading"],
    mounted() {
        setTimeout(() => {
            if (localStorage.getItem("blog_token") != null) {
                //check token from server
                this.$router.push("/Pincode");
            } else {
                this.$router.push("/Login");
            }
        }, 2000)
    },

};
</script>

<style>
.loader {
  background-color: #63ab97;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.fadeout {
  animation: fadeout 2s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
