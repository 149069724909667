const state = {
  RoomList: [],
  RoomData: [],
};

const getters = {
  RoomList: (state) => state.RoomList,
  RoomData: (state) => state.RoomData,
  // getID: state => {
  //     return state.user.id
  // }
};

const mutations = {
  setItems: (state, RoomList) => {
    state.RoomList = JSON.parse(JSON.stringify(RoomList));
  },
  setRoomData: (state, RoomData) => {
    state.RoomList = JSON.parse(JSON.stringify(RoomData));
  },
};

const actions = {

  
  TransferData(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("login/", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              switch (credentials.method) {
                case "login":
                  context.commit("loginSuccess", data.response.user);

                  resolve(data.response.user);

                  //save token
                  localStorage.setItem("blog_token", data.response.token);
                  //save id
                  sessionStorage.setItem("UserID", data.response.user.Username);
                  //save nickname
                  sessionStorage.setItem(
                    "Nickname",
                    data.response.user.Nickname
                  );
                  break;
                case "request_connect":
                  localStorage.clear();
                  sessionStorage.clear();
                  //   localStorage.setItem("fun_token", data.response.token);
                  //   sessionStorage.setItem("HostID", data.response.HostID);
                  //   sessionStorage.setItem("LineID", data.response.LineID);
                  //   sessionStorage.setItem("AgentID", data.response.AgentID);
                  //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);

                  break;
                case "create_pin":
                  //   localStorage.setItem("fun_token", data.response.token);
                  //   sessionStorage.setItem("HostID", data.response.HostID);
                  //   sessionStorage.setItem("LineID", data.response.LineID);
                  //   sessionStorage.setItem("AgentID", data.response.AgentID);
                  //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                  break;
                case "check_token":
                  //   localStorage.setItem("fun_token", data.response.token);
                  //   sessionStorage.setItem("HostID", data.response.HostID);
                  //   sessionStorage.setItem("LineID", data.response.LineID);
                  //   sessionStorage.setItem("AgentID", data.response.AgentID);
                  //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                  break;
                case "check_pin":
                  //   sessionStorage.setItem("HostID", data.response.HostID);
                  //   sessionStorage.setItem("LineID", data.response.LineID);
                  //   sessionStorage.setItem("AgentID", data.response.AgentID);
                  //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                  break;
                default:
                  break;
              }
              resolve(data.response);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },


  get_overview_list(context, Parameter) {
    //Parameter.WebName
    console.log(this._vm.$headers);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("room/get_list.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
          console.log("->");
          console.log(data);
          if (status === 200) {
            //console.log(data);
            if (data.message == "Success") {
              console.log(data);

              context.commit("setItems", data.response.RoomList);

              resolve(data.response);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  get_room_detail(context, Parameter) {
    //Parameter.WebName
    console.log(Parameter);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("room/get_detail.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
          console.log("->");
          console.log(data);
          if (status === 200) {
            //console.log(data);
            if (data.message == "Success") {
              console.log(data);

              context.commit("setRoomData", data.response.RoomData);

              resolve(data.response);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  set_room_detail(context, Parameter) {
    //Parameter.WebName
    //console.log(Parameter);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("room/set_detail.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
          console.log("->");
          console.log(data);
          if (status === 200) {
            //console.log(data);
            if (data.message == "Success") {
              console.log(data);
              context.commit("setRoomData", data.response.RoomData);
              resolve(data.response);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export const web = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
