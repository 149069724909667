const state = {
  MachineList: [],
};

const getters = {
  MachineList: (state) => state.MachineList,
  // getID: state => {
  //     return state.user.id
  // }
};

const mutations = {
  setItems: (state, MachineList) => {
    state.MachineList = JSON.parse(JSON.stringify(MachineList));
  },
};

const actions = {
  load_machine_list(context, Parameter) {
    //Parameter.WebName
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("machine/get_list.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
          console.log("->");
          console.log(data);
          if (status === 200) {
            //console.log(data);
            if (data.message == "Success") {
              console.log(data);

              context.commit("setItems", data.response.MachineList);

              resolve(data.response);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  get_permission(context, Parameter) {
    //Parameter.WebName
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("machine/get_permission.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
          if (status === 200) {
            console.log(data);
            if (data.message == "Success") {
              resolve(data.response.Machine_Permission);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  set_permission(context, Parameter) {
    //Parameter.WebName
    console.log(Parameter);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("machine/set_permission.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
          if (status === 200) {
            console.log(data);
            if (data.message == "Success") {
              context.commit("setItems", data.response.MachineList);
              resolve(data.response.Machine_Permission);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  del(context, Parameter) {
    //Parameter.WebName
    console.log(Parameter);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("machine/del.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
          if (status === 200) {
            console.log(data);
            if (data.message == "Success") {
              context.commit("setItems", data.response.MachineList);
              resolve(data.response.Machine_Permission);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  // add_id(context, Parameter) {
  //   //Parameter WebName id pw  Credit Status
  //   return new Promise((resolve, reject) => {
  //     this._vm.$http
  //       .post("id/add.php", Parameter, this._vm.$headers)
  //       .then(({ data, status }) => {
  //           // console.log(data);
  //         if (status === 200) {
  //           if (data.message == "Success") {
  //             //console.log(data);
  //             context.commit("setItems", data.response.IDList);
  //             resolve(Parameter.ID);
  //           } else {
  //             reject(data.message);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         reject(error);
  //       });
  //   });
  // },
  // del_id(context, Parameter) {
  //   //Parameter WebName id
  //   // console.log(Parameter);
  //   return new Promise((resolve, reject) => {
  //     this._vm.$http
  //       .post("id/del.php", Parameter, this._vm.$headers)
  //       .then(({ data, status }) => {
  //           // console.log(data);
  //         if (status === 200) {
  //           //console.log(data);
  //           if (data.message == "Success") {
  //           //   console.log(data);
  //             context.commit("setItems", data.response.IDList);
  //             resolve(Parameter.ID);
  //           } else {
  //             //console.log(data);
  //             reject(data.message);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         reject(error);
  //       });
  //   });
  // },
  // get_id(context, Parameter) {
  //   //Parameter.WebName
  //   return new Promise((resolve, reject) => {
  //     this._vm.$http
  //       .post("id/get.php", Parameter, this._vm.$headers)
  //       .then(({ data, status }) => {
  //         if (status === 200) {
  //           //console.log(data);
  //           if (data.message == "Success") {
  //             resolve(data.response.ID_DATA);
  //           } else {
  //             //console.log(data);
  //             reject(data.message);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         reject(error);
  //       });
  //   });
  // },
  // edit_id(context, Parameter) {
  //   //Parameter WebName id pw  Credit Status
  //   return new Promise((resolve, reject) => {
  //     this._vm.$http
  //       .post("id/edit.php", Parameter, this._vm.$headers)
  //       .then(({ data, status }) => {
  //           console.log(data);
  //         if (status === 200) {
  //           if (data.message == "Success") {
  //             console.log(data);
  //             context.commit("setItems", data.response.IDList);
  //             resolve(Parameter.ID);
  //           } else {
  //             reject(data.message);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         reject(error);
  //       });
  //   });
  // },
};

export const machine = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
