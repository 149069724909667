const state = {
  IDList: [],
};

const getters = {
  IDList: (state) => state.IDList,
  // getID: state => {
  //     return state.user.id
  // }
};

const mutations = {
  setItems: (state, IDList) => {
    state.IDList = JSON.parse(JSON.stringify(IDList));
  },
};

const actions = {
  load_id_list(context, Parameter) {
    //Parameter.WebName
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("id/get_list.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
            console.log("->" . data);
          if (status === 200) {
            //console.log(data);
            if (data.message == "Success") {
              console.log(data);

              context.commit("setItems", data.response.IDList);

              resolve(data.response);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  add_id(context, Parameter) {
    //Parameter WebName id pw  Credit Status
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("id/add.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
            // console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              //console.log(data);
              context.commit("setItems", data.response.IDList);
              resolve(Parameter.ID);
            } else {
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  del_id(context, Parameter) {
    //Parameter WebName id
    // console.log(Parameter);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("id/del.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
            // console.log(data);
          if (status === 200) {
            //console.log(data);
            if (data.message == "Success") {
            //   console.log(data);
              context.commit("setItems", data.response.IDList);
              resolve(Parameter.ID);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  get_id(context, Parameter) {
    //Parameter.WebName
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("id/get.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
          if (status === 200) {
            //console.log(data);
            if (data.message == "Success") {
              resolve(data.response.ID_DATA);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  edit_id(context, Parameter) {
    //Parameter WebName id pw  Credit Status
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("id/edit.php", Parameter, this._vm.$headers)
        .then(({ data, status }) => {
            console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit("setItems", data.response.IDList);
              resolve(Parameter.ID);
            } else {
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export const id = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
