const state = {
  isLoggingIn: false,
  errors: {},
  user: {
    Username: "null",
    Nickname: null,
    Status: 0, //1 normal
  },
};

const getters = {
  ID: (state) => state.user.id,
  Token: (state) => state.user.token,
  // getID: state => {
  //     return state.user.id
  // }
};

const mutations = {
  loginSuccess(state, user) {
    state.user.Username = user.Username;
    state.user.Nickname = user.Nickname;
    state.user.Status = user.Status;
    console.log(
      state.user.Username + " " + state.user.Nickname + " " + state.user.Status
    );
  },
};

const actions = {
  TransferData(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("login/", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              switch (credentials.method) {
                case "login":
                  context.commit("loginSuccess", data.response.user);

                  resolve(data.response.user);

                  //save token
                  localStorage.setItem("blog_token", data.response.token);
                  //save id
                  sessionStorage.setItem("UserID", data.response.user.Username);
                  //save nickname
                  sessionStorage.setItem(
                    "Nickname",
                    data.response.user.Nickname
                  );
                  break;
                case "request_connect":
                  localStorage.clear();
                  sessionStorage.clear();
                  //   localStorage.setItem("fun_token", data.response.token);
                  //   sessionStorage.setItem("HostID", data.response.HostID);
                  //   sessionStorage.setItem("LineID", data.response.LineID);
                  //   sessionStorage.setItem("AgentID", data.response.AgentID);
                  //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);

                  break;
                case "create_pin":
                  //   localStorage.setItem("fun_token", data.response.token);
                  //   sessionStorage.setItem("HostID", data.response.HostID);
                  //   sessionStorage.setItem("LineID", data.response.LineID);
                  //   sessionStorage.setItem("AgentID", data.response.AgentID);
                  //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                  break;
                case "check_token":
                  //   localStorage.setItem("fun_token", data.response.token);
                  //   sessionStorage.setItem("HostID", data.response.HostID);
                  //   sessionStorage.setItem("LineID", data.response.LineID);
                  //   sessionStorage.setItem("AgentID", data.response.AgentID);
                  //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                  break;
                case "check_pin":
                  //   sessionStorage.setItem("HostID", data.response.HostID);
                  //   sessionStorage.setItem("LineID", data.response.LineID);
                  //   sessionStorage.setItem("AgentID", data.response.AgentID);
                  //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                  break;
                default:
                  break;
              }
              resolve(data.response);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },

  login(context, login_data) {
    console.log(login_data);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("login/", login_data, this._vm.$headers)
        .then(({ data, status }) => {
          if (status === 200) {
            console.log(data);
            if (data.message == "Success") {
              //console.log(data);
              context.commit("loginSuccess", data.response.user);

              resolve(data.response.user);

              //save token
              localStorage.setItem("blog_token", data.response.token);
              //save id
              sessionStorage.setItem("UserID", data.response.user.Username);
              //save nickname
              sessionStorage.setItem("Nickname", data.response.user.Nickname);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
  create_pincode(context, parameter) {
    // let parameter = {
    //     verify_code: p_code,
    //     method: "create"
    // };
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("verify/create.php", parameter, this._vm.$headers)
        .then(({ data, status }) => {
          if (status === 200) {
            //console.log(data);
            if (data.message == "Success") {
              //console.log(data);
              //save id
              sessionStorage.setItem("UserID", data.response.user.Username);
              //save nickname
              sessionStorage.setItem("Nickname", data.response.user.Nickname);
              resolve("Success");
              // }else{
              //     //console.log(data);
              //     reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
  verify_pincode(context, parameter) {
    // let parameter = {
    //     verify_code: p_code,
    //     method: "create"
    // };
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("verify/", parameter, this._vm.$headers)
        .then(({ data, status }) => {
          if (status === 200) {
            console.log(data);
            if (data.message == "Success") {
              //     //console.log(data);
              //save id
              sessionStorage.setItem("UserID", data.response.user.Username);
              //save nickname
              sessionStorage.setItem("Nickname", data.response.user.Nickname);
              resolve(data.response);
            } else {
              //     //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
